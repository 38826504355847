import Vue from "vue";
import Router from "vue-router";
//import NotFound from './views/NotFound'
//import Forbidden from './views/Forbidden'
import Login from "./views/Login";
import Logout from "./views/Logout";
//import Home from './views/Home'
import store from "./store";
import permisosMiddleware from './middleware/permisos';
import propertiesSap from './middleware/propertiesSap';
import ClientVar from './utils/ClientVar';

import Keycloak from "keycloak-js";
import AxiosApi from "./plugins/axiosApi";

Vue.use(Router);




let router = new Router({
  //mode: "hash",
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [

    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          name: "dashboard",
          
          path: "",
          component: () => import("@/views/dashboard/Dashboard"),
          meta: {
            titleApp: "Dashboard",
            requireAuth: true,
          },
        },

        // --------------- Flujo Elementos Registrales INICIO ---------------
        {
          name: "elementos_registrales",
          titleApp: "Venta Elementos Registrales - Selección de Productos",
          path: "elementos_registrales",
          component: () => import("@/views/portal/ElementosRegistrales"),
          meta: {
            titleApp: "<span class='darken-1'>Compra / </span>Comprar Elementos Registrales",            
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,                        
            permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_REGISTRALES],
          },
        },
        {
          name: "detalle_pedido",
          path: "detalle_pedido",
          component: () => import("@/views/portal/DetallePedido"),
          meta: {
            titleApp: "Venta Elementos Registrales - Detalle del Pedido",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,                        
            permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_REGISTRALES],
          },
        },
        {
          name: "pedido_finalizado",
          path: "PedidoFinalizado",
          component: () => import("@/views/portal/PedidoFinalizado"),
          meta: {
            titleApp: "Venta Elementos Registrales - Pedido Finalizado",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,                        
            permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_REGISTRALES],
          },
        },
        // --------------- Flujo Elementos Registrale FIN ----------------------
        // --------------- Flujo Comprar Elementos Aduana INICIO ---------------
        {
          name: "elementos_aduana_compra",
          path: "elementos_aduana_compra",
          component: () => import("@/views/portal/ElementosAduanaCompra"),
          meta: {
            titleApp: "Comprar Elementos Aduana - Selección de Productos",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_ADUANA],            
          },
        },
        {
          name: "elementos_aduana_compra_detalle_pedido",
          path: "elementos_aduana_compra_detalle_pedido",
          component: () => import("@/views/portal/DetallePedido"),
          meta: {
            titleApp: "Comprar Elementos Aduana - Detalle del Pedido",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_ADUANA],            
          },
        },
        {
          name: "elementos_aduana_compra_pedido_finalizado",
          path: "elementos_aduana_compra_pedido_finalizado",
          component: () => import("@/views/portal/PedidoFinalizado"),
          meta: {
            titleApp: "Comprar Elementos Aduana - Pedido Finalizado",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_ADUANA],     
          },
        },
        // --------------- Flujo Comprar Elementos Aduana FIN --------------
        // --------------- Flujo Vender Elementos Aduana INICIO ------------
        {
          name: "elementos_aduana_venta",
          path: "elementos_aduana_venta",
          component: () => import("@/views/portal/ElementosAduanaVenta"),
          meta: {
            titleApp: "Vender Elementos Aduana - Selección de Cliente",
            /*middleware: [permisosMiddleware],*/
            requireAuth:true,
            store: true,
            permiso:'vender_elementos_aduana',
          },
        },
        {
          name: "elementos_aduana_venta_seleccion",
          path: "elementos_aduana_venta_seleccion",
          component: () => import("@/views/portal/ElementosAduanaCompra"),
          meta: {
            titleApp: "Vender Elementos Aduana - Selección de Productos",
            /*middleware: [permisosMiddleware],*/
            requireAuth:true,
            store: true,            
            permiso:'vender_elementos_aduana',
          },
        },
        {
          name: "elementos_aduana_venta_detalle_pedido",
          path: "elementos_aduana_venta_detalle_pedido",
          component: () => import("@/views/portal/DetallePedido"),
          meta: {
            titleApp: "Vender Elementos Aduana - Detalle del Pedido",
            /*middleware: [permisosMiddleware],*/
            requireAuth:true,
            store: true,            
            permiso:'vender_elementos_aduana',
          },
        },
        {
          name: "elementos_aduana_venta_pedido_finalizado",
          path: "elementos_aduana_venta_pedido_finalizado",
          component: () => import("@/views/portal/PedidoFinalizado"),
          meta: {
            titleApp: "Vender Elementos Aduana - Pedido Finalizado",
            /*middleware: [permisosMiddleware],*/
            requireAuth:true,
            store: true,            
            permiso:'vender_elementos_aduana',

          },
        },
        // --------------- Flujo Vender Elementos Aduana FIN --------------
        {
          name: "elementos_aduana",
         
          path: "elementos_aduana",
          component: () => import("@/views/portal/ElementosAduana"),
          meta: {
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,
            permiso:[Vue.prototype.$COMPRAR_ELEMENTOS_ADUANA],     
          },
        },
        // --------------- Flujo Cuenta corriente INICIO --------------
        {
          name: "cuenta_corriente",
          titleApp: "Cuenta Corriente",
          path: "cuenta_corriente",
          component: () => import("@/views/portal/CuentaCorriente"),
          meta: {
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,            
            permiso:[Vue.prototype.$LISTADO_CTA_CTE],
          },
        },
        {
          name: "cuenta_corriente_consulta",
          path: "cuenta_corriente_consulta",
          component: () => import("@/views/portal/CuentaCorrienteConsulta"),
          meta: {
            titleApp: "Consultar Cuenta Corriente",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,            
            permiso:[Vue.prototype.$LISTADO_CTA_CTE],
          },
        },
        // --------------- Flujo Cuenta corriente FIN --------------
        // --------------- Flujo Transacciones INICIO --------------
        {
          name: "transacciones",
          path: "transacciones",
          component: () => import("@/views/portal/Transacciones"),
          meta: {
            titleApp: "Listado de Transacciones",            
            requireAuth:true,
            store: true,                        
          },
        },
        {
          name: "transacciones_detalle",
          path: "transacciones/:transaccion_id/transaccion_detalle",
          component: () => import("@/views/portal/TransaccionesDetalle"),
          meta: {
            titleApp: "Detalle de Transacciones",            
            requireAuth:true,
            store: true,            
          },
        },
        {
          name: "gestion_stock_estampillas",
          path: "stock-estampillas/gestion_stock_estampillas",
          component: () =>
            import("@/views/portal/stock-estampillas/GestionStockEstampillas"),
          meta: {
            titleApp: "Gestión de Stock de Estampillas",
            /*middleware: [permisosMiddleware],*/
            requireAuth:true,
            store: true,            
            permiso:'informe_stock_estampillas',
          },
        },
        {
          name: "informe_stock_estampillas",
          path: "stock-estampillas/informe_stock_estampillas",
          component: () =>
            import("@/views/portal/stock-estampillas/InformeStockEstampillas"),
          meta: {
            titleApp: "Informe de Stock de Estampillas",
            /*middleware: [permisosMiddleware],*/
            requireAuth:true,
            store: true,            
            permiso:'informe_stock_estampillas',
          },
        },
        {
          name: "informe_almacen_venta",
          path: "stock-estampillas/informes/almacen-venta",
          component: () =>
            import("@/views/portal/stock-estampillas/informes/AlmacenVenta"),
          meta: {
            titleApp: "Almacén de Venta",
            /*middleware: [permisosMiddleware],*/
            requireAuth:true,
            store: true,
            permiso:'almacen_venta_list',
          },
        },
        {
          name: "ejemplo_pedido",
          path: "EjemploImagen",
          component: () => import("@/views/portal/EjemploImagen"),
          meta: {
            titleApp: "Ejemplo Pedido",
            requireAuth:true,
          },
        },
        {
          name: "ejemplo_pedido_adquirir_saldo",
          path: "EjemploImagenAdquirirSaldo",
          component: () => import("@/views/portal/EjemploImagen"),
          meta: {
            titleApp: "Ejemplo Pedido",
            requireAuth:true,
          },
        },
        // --------------- Flujo Adquirir Saldo en Cuenta Corriente INICIO ---------------
        {
          name: "adquirir_saldo_cta_cte",
          path: "adquirir_saldo_cta_cte",
          component: () => import("@/views/portal/adquirirSaldo/AdquirirSaldoCtaCte"),
          meta: {
            titleApp: "Adquirir Saldo en Cuenta Corriente",            
            /*middleware:[propertiesSap],*/
            requireAuth:true,
            store: true,                        
            permiso:[Vue.prototype.$ADQUIRIR_SALDO_CTA_CTE],            
          },
        },
        {
          name: "detalle_pedido_adquirir_saldo",
          path: "detalle_pedido_adquirir_saldo",
          component: () => import("@/views/portal/adquirirSaldo/DetallePedidoAdquirirSaldo"),
          meta: {
            titleApp: "Adquirir Saldo Cuenta Corriente - Detalle del Pedido",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,            
            /*permiso:'adquirir_saldo_cta_cte'*/
            permiso:[Vue.prototype.$ADQUIRIR_SALDO_CTA_CTE],
          },
        },
        {
          name: "pedido_finalizado_adquirir_saldo",
          path: "pedido_finalizado_adquirir_saldo",
          component: () => import("@/views/portal/adquirirSaldo/PedidoFinalizadoAdquirirSaldo"),
          meta: {
            titleApp: "Adquirir Saldo - Pedido Finalizado",
            /*middleware: [propertiesSap],*/
            requireAuth:true,
            store: true,            
            /*permiso: 'adquirir_saldo_cta_cte',*/
            permiso:[Vue.prototype.$ADQUIRIR_SALDO_CTA_CTE],
          },
        },
        // --------------- Flujo Adquirir Saldo en Cuenta Corriente FIN ---------------

        // --------------- Flujo Venta estampillas ANMAC INICIO ---------------
        {
          name: "estampillas_ANMAC_venta",
          path: "estampillas_ANMAC_venta",
          component: () => import("./views/portal/estampillasAnmac/EstampillasAnmac.vue"),
          meta: {
            titleApp: "Venta Estampillas ANMAC - Selección de Cliente",
            /*middleware: [permisosMiddleware],*/
            requireAuth:true,
            store: true,
            /*permiso:'estampillas_anmac_list',*/
            permiso:'vender_estampillas_anmac',

          },
        },
        {
          name: "estampillas_Anmac_Venta_productos",
          path: "estampillas_Anmac_Venta_productos",
          component: () => import("@/views/portal/estampillasAnmac/EstampillasAnmacVenta"),
          meta: {
            titleApp: "Venta Estampillas ANMAC - Selección de Productos",
            /*middleware: [permisosMiddleware],*/
            requireAuth:true,
            store: true,
            /*permiso:'estampillas_anmac_list',*/
            permiso:'vender_estampillas_anmac',
          },
        },
        {
          name: "detalle_estampillas_venta_detalle_pedido",
          path: "detalle_estampillas_venta_detalle_pedido",
          component: () => import("@/views/portal/estampillasAnmac/DetallePedidoEstampillas"),
          meta: {
            titleApp: "Venta Estampillas ANMAC - Detalle del Pedido",
            /*middleware: [permisosMiddleware],*/
            requireAuth:true,
            store: true,
            /*permiso:'estampillas_anmac_list',*/
            permiso:'vender_estampillas_anmac',
          },
        },
        {
          name: "pedido_finalizado_anmac",
          path: "PedidoFinalizadoAnmac",
          component: () => import("@/views/portal/estampillasAnmac/PedidoFinalizadoAnmac"),
          meta: {
            titleApp: "Venta Estampillas ANMAC - Pedido Finalizado",
            /*middleware: [permisosMiddleware],*/
            requireAuth:true,
            store: true,
            /*permiso:'estampillas_anmac_list',*/
            permiso:'vender_estampillas_anmac',
          },
        },
        // --------------- Flujo Venta estampillas ANMAC FIN ---------------
        {
          name: "cliente_alta",
          path: "cliente_alta",
          component: () => import("@/views/portal/ClienteAlta"),
          meta: {
            titleApp: "Alta de Cliente Nuevo",
            /*middleware: [permisosMiddleware],*/
            requireAuth:true,
            store: true,
            /*permiso:'clientes_create',*/
            permiso:'alta_cliente',
          },
        },
        {
          name: 'admin_solicitud_usuario',
          path: '/admin/admin_solicitud_usuario',
          component: () => import('@/views/cruds/SolicitudesUsuarios'),
          meta: {
            titleApp: 'Solicitudes Nuevo Concesionario',
            requireAuth: true,
            /*middleware: [permisosMiddleware],*/
            store: true,
            permiso:'gestionar_solicitud_concesionario',
          },
        },
        {
          name: 'admin_zona_archivos',
          path: '/zona_archivos',
          component: () => import('@/views/cruds/ZonaDescagas'),
          meta: {
            titleApp: 'Zona Descargas',
            requireAuth: true,
            /*middleware: [permisosMiddleware],*/
            store: true,
            /*permiso:'listado_zona_descargas',*/
          },
        },
        {
          name: 'gestion_stock_estampillas',
          path: '/stock/estampillas',
          component: () => import('@/views/stock/StockEstampillas'),
          meta: {
            titleApp: 'Gestion de Stock de Estampillas',
            requireAuth: true,
            /*middleware: [permisosMiddleware],*/
            store: true,            
            permiso:'informe_stock_estampillas',

          },
        },
        {
          name: 'notificaciones_usuarios',
          path: '/notificaciones_usuarios',
          component: () => import('@/views/cruds/NotificacionesUsuarios'),
          meta: {
            titleApp: 'Notificaciones de Usuarios',
            requireAuth: true,
            /*middleware: [permisosMiddleware],*/
            store: true,
            //permiso:'solicitudes_usuarios_list',
          }
        },

        {
          name: 'chat_page',
          path: 'chat_page',
          component: () => import('@/views/dashboard/components/core/ChatPage'),
          meta: {
            titleApp: 'Chat',
            requireAuth: true,
          },
        },
        {
          name: 'perfil',
          path: 'perfil',
          component: () => import('@/views/dashboard/components/core/UserProfile'),
          meta: {
            titleApp: 'Mi Perfil',
            requireAuth: true,
          },
        }


      ],
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import('@/views/Unauthorized'),
      meta: {
        requireAuth: false,
      },

    },
    {
      path: "/solicitud_usuario_concesionario",
      name: "solicitud_usuario_concesionario",
      component: () => import("@/views/SolicitudUsuarioConcesionario"),
      meta: {
        titleApp: "Solicitudes Nuevo Concesionario",
        requireAuth: false,
      },

    },

    {
      name: 'SolicitudEstado',
      path: '/solicitud_estado/:id?',
      component: () => import('@/views/SolicitudEstado'),
      meta: {
        titleApp: 'Estado de Solicitud',
        requireAuth: false,
      },
    },
    /*{
      name: "home",
      path: "/",
      component: () => import("@/views/Home"),
      meta: {
        titleApp: "Home",
        requireAuth: false,
      },
    }
      {
        path: '/',
        redirect: '/portal',
        meta: {
          titleApp: "Home",
          requireAuth: true,
        },
      },*/



  ],
});



router.beforeEach((to, from, next) => {
  if (!to.meta.requireAuth) {
  next();
  }
  else if (to.meta.middleware) {
    const middlewarePromises = to.meta.middleware.map(middleware => {
      return new Promise((resolve, reject) => {
        middleware({ to, from, next: resolve, router, store });
      });
    });

    Promise.all(middlewarePromises)
      .then(() => {
        console.log("Todos los middlewares han sido ejecutados correctamente");
        next();
      })
      .catch(error => {
        console.error("Error al ejecutar los middlewares:", error);
        next(false);
      });
  } else {
    next();
  }
});

//export default router;

export default router;
