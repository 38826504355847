import axios from "axios";


export default {

    install(Vue, options) {

        Vue.prototype.$initialize= function(loading=false) {
            let vm = this;
            if(loading)
             Swal.alertGetInfo("Buscando información");
            vm.$axiosApi
                .get(vm.route)
                .then((r) => {
                  if(loading)
                   Swal.close();
                    vm.desserts = r.data;
                })
                .catch(function (error) {
                  console.log(error);
                    vm.snackbar = true;
                    vm.text = "Error al obtener datos. Error: " + error;
                    vm.color = "error";
                    if(loading)
                    Swal.close();
                });
        },

        Vue.prototype.$enabledItem= function(item) {
            let vm = this;
            let id = item.id;
            let titulo = vm.title;
            if (titulo.substring( titulo.length - 1)== 's')
             titulo = titulo.substring(0, titulo.length - 1);
            let registro = (item.nombre)?item.nombre:'';
            let activar = item.activo == 1 ? 0 : 1;
            let form = {'activo':activar};
            let msj =
                item.activo == 1
                    ? "Desea desactivar el " + titulo + " " + registro + " ?"
                    : "Desea activar el " + titulo + " " + registro + " ?";

                    vm.$confirm({
                message: msj,
                button: {
                    no: "Cancelar",
                    yes: "Confirmar",
                },

                callback: (confirm) => {
                    let respuesta = item.activo == 1 ? 4 : 5;
                    if (confirm) {
                        vm.$axiosApi
                            //.put(url)
                            .put(vm.route+'_enable', id, form)
                            .then((r) => {
                                vm.$respuesta(vm, r, respuesta);
                            })
                            .catch((e) => {
                                vm.$respuesta(vm, e, respuesta, true);
                            });
                    }
                },
            });
        }

        Vue.prototype.$jsonEnabledItem= function(item) {
          this.showDialog = this.dialog == true ? false : "";
          let solicitud = item.archivo + "/" + item.entidad;

          let id = item.id;
          let textSwalOk = "";
          let textQuestion = "";
          let newEstadoId = "";
          switch (item.activo) {
            case 1:
              textSwalOk = "Inactivado";
              textQuestion = "Inactivar";
              newEstadoId = 0;
              break;
            case 0:
              textSwalOk = "Activado";
              textQuestion = "Activar";
              newEstadoId = 1;

              break;
          }

          Swal.fire({
            title: " Desea " + textQuestion + " el archivo (" + item.archivo + ")?",

            icon: "question",

            showCancelButton: true,

            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: "Aguarde un instante!",
                icon: "info",
                html: "Procesando informacion",
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                  Swal.showLoading();
                },
              });
              let formData = {
                id: id,
                activo: newEstadoId,
              };
              //solo front
              let objeto = this.desserts.data.find((item) => item.id === id);

              // Si el objeto se encuentra y 'visible' es "1", se cambia a "2"
              if (objeto) {
                objeto.activo = newEstadoId;
              }
              Swal.close();

              this.snackbar = true;
              this.color = "success";
              this.text = "El archivo ha sido " + textSwalOk;

            } else {
              this.showDialog = this.dialog == true ? true : false;
              console.log("cancelar");
            }
          });
          console.log("textarea");
        },


        Vue.prototype.$deleteItem =  function (id, nombre) {
            let vm = this;

              let titulo = vm.title;
            if (titulo.substring( titulo.length - 1)== 's')
             titulo = titulo.substring(0, titulo.length - 1);

            vm.$confirm({
                message: "¿Desea ELIMINAR " + titulo + " " + nombre + "?",
                button: {
                    no: "Cancelar",
                    yes: "Eliminar",
                },

                callback: async function (confirm)  {


                    if (confirm) {
                        Swal.alertGetInfo("Eliminando Registro");

                        await vm.$axiosApi
                            .delete(vm.route, id)
                            .then((r) => {
                                vm.$respuesta(vm, r, 0);
                            })
                            .catch((e) => {
                                vm.$respuesta(vm, e, 0, true);
                            });
                            Swal.close();
                    }
                },
            });

        }

        Vue.prototype.$jsonDeleteItem =  function (id, nombre) {
          let vm = this;


          vm.$confirm({
              message: "¿Desea ELIMINAR " + nombre + "?",
              button: {
                  no: "Cancelar",
                  yes: "Eliminar",
              },

              callback: async function (confirm)  {


                  if (confirm) {
                      Swal.alertGetInfo("Eliminando Registro");
                      const index = vm.desserts.data.findIndex(item => item.id === id);
                      if (index !== -1) {
                        vm.desserts.data.splice(index, 1);
                      }
                          Swal.close();
                          vm.snackbar = true;
                          vm.color = "success";
                          vm.text = "El registro "+nombre+" ha sido eliminado" ;

                  }
              },
          });

      }



        Vue.prototype.$save = async function() {
            let vm = this;
            console.log(vm.editedItem);

            if (vm.$refs.form.validate()) {

                if (vm.editedIndex != -1) {
                  Swal.alertGetInfo("Actualizando información");

                  await vm.$axiosApi.put(vm.route, vm.editedIndex, vm.editedItem)
                    .then((r) => {
                        vm.$respuesta(vm, r, 2);

                    }).catch((e) => {
                        vm.$respuesta(vm, e, 2, true);

                    });

                } else {
                  Swal.alertGetInfo("Registrando información");

                  await vm.$axiosApi.post(vm.route, vm.editedItem)
                    .then((r) => {
                        vm.$respuesta(vm, r, 1);
                    }).catch((e) => {
                        vm.$respuesta(vm, e, 1, true);
                        Swal.close();
                    });
                }
                Swal.close();
                vm.$close();
            }
        }

        Vue.prototype.$jsonSave = async function() {
          let vm = this;

          if (vm.$refs.form.validate()) {

              if (vm.editedIndex != -1) {
                Swal.alertGetInfo("Actualizando información");

                 // Buscar el objeto por su 'id'
                  let objeto = vm.desserts.data.find(item => item.id === vm.editedIndex);

                  // Si el objeto se encuentra, actualizamos sus propiedades
                  if (objeto) {
                      for (let key in vm.editedItem) {
                          if (objeto.hasOwnProperty(key)) {
                              objeto[key] = vm.editedItem[key];
                          }
                      }
                  }



              } else {
                Swal.alertGetInfo("Registrando información");

                vm.desserts.data.unshift(vm.editedItem);
              }
              setTimeout(() => {
                Swal.close();
                let msg= "creado";
                if (vm.editedIndex != -1) {
                msg= "actualizado";
                }
                vm.dialog =false;
                vm.snackbar = true;
                vm.color = "success";
                vm.text = "El registro ha sido "+msg+" con exito" ;
                vm.$forceUpdate();
              }, 1000);
          }
      }

        Vue.prototype.$saveExternal = async function() {
          let vm = this;

          if (vm.$refs.form.validate()) {

              if (vm.editedIndex > -1) {
                Swal.alertGetInfo("Actualizando información");

                await vm.$axiosApi.putExternal(vm.route, vm.editedIndex, vm.editedItem)
                  .then((r) => {
                      vm.$respuesta(vm, r, 2);

                  }).catch((e) => {
                      vm.$respuesta(vm, e, 2, true);

                  });

              } else {
                Swal.alertGetInfo("Registrando información");

                await vm.$axiosApi.postExternal(vm.route, vm.editedItem)
                  .then((r) => {
                      vm.$respuesta(vm, r, 1);
                  }).catch((e) => {
                      vm.$respuesta(e, 1, true);
                  });
              }
              Swal.close();
              //vm.$close();
          }
      }

        Vue.prototype.$editItem = async function(id){
            let vm = this;
            //aca toque
            Swal.alertGetInfo("Obteniendo información <br><b></b>");
            /*vm.snackbar = true;
            vm.text = "Obteniendo datos ";
            vm.color = "primary";
            */
            await vm.$axiosApi
            .getById(vm.route, id)
                .then((r) => {
                    Vue.prototype.$respuesta(vm, r, 3);
                    Swal.close();
                    //vm.snackbar = false;

                })
                .catch(function (error) {
                  Swal.close();
                    Vue.prototype.$respuesta(vm, error, 3, true);
                });


        }
        Vue.prototype.$sleep = async function (ms) {
          return new Promise((resolve) => setTimeout(resolve, ms));
        }

        Vue.prototype.$formatMoney = function (value) {
          if (!value) return null;

          return new Intl.NumberFormat("es-AR").format(value);
      }

        Vue.prototype.$formatDate = function (date) {
            if (!date) return null;
            let hora =date.slice(10);
            date =date.slice(0, 10);

            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year} ${hora}`;
        }

        Vue.prototype.$formatDateDb= function (date) {
          if (!date) return null;

          const [day, month, year] = date.split("/");
          return `${year}-${month}-${day}`;
        }

        Vue.prototype.$formatDateFromTimestamp= function (date) {
          if (!date) return null;
          const formattedDate = Vue.prototype.moment(date).format('DD/MM/YYYY HH:mm:ss');
          return (formattedDate);
          //const [day, month, year] = date.split("/");
          //return `${year}-${month}-${day}`;
        }


        Vue.prototype.$close= function() {

            let vm = this;

            vm.dialog = false;
            vm.valid = true;
            vm.$resetValidation();
            vm.$nextTick(() => {
                vm.editedItem = Object.assign({}, vm.defaultItem);
                vm.editedIndex = -1;
            });
        }

        Vue.prototype.$resetValidation= function() {
            let vm = this;
            vm.$refs.form.resetValidation();
        },


        Vue.prototype.$capitalize =function(s) {
            if (typeof s !== "string") return "";
            return s.charAt(0).toUpperCase() + s.slice(1);
        },

        Vue.prototype.$getQuery = async function(url) {
            let vm = this;

            let records =  await vm.$axiosApi
                .get(url)
                .then((r) => {

                    return r.data;
                })
                .catch(function (error) {
                    vm.snackbar = true;
                    vm.text = "Error al obtener datos. Error: " + error;
                    vm.color = "error";
                });
            return records;
        },



        Vue.prototype.$respuesta = function(vm,r,tipo,excepcion  = false,recargarGrilla=true){

          let text1 = "Creado";
          let text2 = "crear";
          switch (tipo) {
            case 0:
              text1 = "Eliminado";
              text2 = "eliminar";
              break;
            case 2:
              text1 = "Actualizado";
              text2 = "actualizar";
              break;
            case 3:
              text1 = "Obtener";
              text2 = "actualizar";
              break;
            case 4:
              text1 = "Desactivado";
              text2 = "desactivar";
              break;
            case 5:
              text1 = "Activo";
              text2 = "activar";
              break;
          }

          if (excepcion == false) {

            if (tipo == 3) {

              if (r.data && r.data.code == 200) {

                vm.editedIndex = (vm.route!='roles')? r.data.data.id:r.data.data.name;
                delete r.data.data.id;

                vm.editedItem = Object.assign({}, r.data.data);
                vm.dialog = true;
              } else {

                vm.snackbar = true;
                vm.text =
                  "<p>Ocurrió un problema al recuperar el registro.<br><hr><b>Codigo:</b>" +
                  r.data.code +
                  " - <b>Error:</b> " +
                  r.data.message +
                  "</p>";
                vm.color = "error";
              }
            } else {
              if (r.data && r.data.code == 200 || r.data.code == 201) {
                vm.color = "success";
                vm.snackbar = true;
                vm.text = "Registro " + text1;


                if (tipo == 1 || tipo == 2) {
                  vm.$close();
                }
                if(recargarGrilla)
                vm.$initialize();
              } else {
                vm.snackbar = true;
                vm.text =
                  "<p>Ocurrió un problema al " +
                  text2 +
                  " el registro.<br><hr><b>Codigo:</b>" +
                  r.data.code +
                  " - <b>Error:</b> " +
                  r.data.message +
                  "</p>";
                vm.color = "error";
              }
            }
          } else {
            vm.snackbar = true;
            vm.text = "Error al " + text2 + " los datos. Error: " + r.response.data.message;
            vm.color = "error";
            return r;
          }
        }


        Vue.prototype.$getCss= function (value, item)
        {
          let verde = "grid-green rounded-pill text-center mr-9 pr-1 pl-1";
          let amarillo = "grid-yellow rounded-pill text-center mr-9";
          let rojo = "grid-red rounded-pill text-center p-1 mr-9 pr-1 pl-1";

          switch (item) {
            case "activo":
              if (value== "1") return verde;
              if (value  === "") return "white" ;
              else return rojo;
              break;
              case "estado":
              if (value  == "enviado") return amarillo;
              if (value  == "leido") return verde ;
              if (value== "ACTIVO") return verde;
              if (value== "INACTIVO") return rojo;
              else return rojo;
              break;

              case "prioridad":
              if (value  == "ALTA") return rojo;
              if (value  == "MEDIA") return amarillo ;
              else return verde;
              break;
              case "estado_solicitud":
              if (value  == 1) return amarillo;
              if (value  == 2) return verde ;
              else return rojo;
              break;
              case "estado_notificacion":
              if (value  == 1) return verde;
              else return rojo;
              break;
          }
        }
      Vue.prototype.$getCssTransacciones= function (value)
      {
        let verde = "grid-green rounded-pill text-center mr-9 pr-1 pl-1";
        let azul = "grid-blue rounded-pill text-center mr-9 pr-1 pl-1";
        let amarillo = "grid-yellow rounded-pill text-center mr-9";
        let rojo = "grid-red rounded-pill text-center p-1 mr-9 pr-1 pl-1";

        switch (value.toLowerCase()) {
          case "activo":
            return amarillo;
            break;
          case "facturado":
            return verde;
            break;
          case "completado":
            return azul;
        }
      }


        Vue.prototype.$getDate = function () {
            return Math.floor(Date.now() / 1000);
        }

        Vue.prototype.$getValue= function (value, item)
        {


          switch (item) {
            case "activo":
              if (value== 1) return "Si";
              if (value == 0) return "No";
              if (value == null) return "";
            break;
            case "estado_solicitud":
              if (value== 1) return "PENDIENTE";
              if (value == 2) return "ACEPTADA";
              if (value == 3) return "RECHAZADA";
            break;
            case "estado_notificacion":
              if (value== 1) return "SI";
              else return "NO";
            break;
          }
        }

        Vue.prototype.$isClient = async function (value) {
          try {
            if (value) {
              
              let cuit = value.replace(/-/g, "");
              if (cuit.length == 11) {
                let routeBussinesPartnersIsClient = 'bussinessPartnerIsClient';
                //let cuit = value.replace(/-/g, "");
                cuit = "C" + cuit;
                let params = cuit + "/TST_MJV20";
                //console.log(params);
    
                const response = await this.$axiosApi.getByCriteria(
                  routeBussinesPartnersIsClient,
                  params
                );
    
                return response.data.data.isClient;
              } else {
                return 0;
              }
            }
          } catch (error) {
            Swal.close();
            /*console.log(error.code);
              console.log(error.message);
              console.log(error.response.data);*/
            //console.error("Error al hacer la solicitud POST:", error);
            return error.response.status;
          }
        }

        Vue.prototype.$isUser = async function (value) {
          try {
            if (value) {
              const pattern = /^$|.+@.+\..+/;
              if (!pattern.test(value)) {
                return false;
              }
              else{
                let routeIsUser = 'isUser';                    
                const response = await this.$axiosApi.getByCriteria(
                  routeIsUser,
                  value
                );
    //console.log(response.data.data.isUser);
                return response.data.data.isUser==1?true:false;
              }
                                                                      
            }
          } catch (error) {
            Swal.close();
          
            return error.response.status;
          }
        }

       

        window.Swal.alertGetInfo= function (msgHtml)
        {

          Swal.fire({
            title: "Aguarde un instante!",
            icon: "info",
            html: msgHtml,
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading();
            },
          });
        }

        window.Swal.alertError= function (title = "Atención",msgHtml = "Se ha producido un error")
        {

          Swal.fire({
            title: title,
            html: msgHtml,
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Aceptar",
            hideClass: {  popup: 'animate__animated animate__fadeOutUp' },
          });
        }



        window.Swal.objSuccess = function (title, msgHtml){

          let r = { title: title,
            icon: "success",
            html: msgHtml,
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Aceptar",
            hideClass: {  popup: 'animate__animated animate__fadeOutUp' },
           };
           return r;

        };

        window.Swal.objQuestion = function (title, msgHtml){

          let q = {
            title: title,
            html: msgHtml,
            icon: "question",
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonColor: "green",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
            hideClass: {  popup: 'animate__animated animate__fadeOutUp' },

          };
           return q;

        };

        window.Swal.alert= function (title = "Atención",msgHtml = "Se ha producido un error")
        {

          Swal.fire({
            title: title,
            html: msgHtml,
            icon: "info",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Aceptar",
            hideClass: {  popup: 'animate__animated animate__fadeOutUp' },
          });
        }

        Vue.prototype.$verifyIdentifiers = async function (tipo, documento) {
          let vm = this;
          if (tipo && documento) {
            const forLoopIdentifiers = async (tipo, documento) => {
              let dni = vm.editedItem.cuit.substr(3, 8);
              let idTipoDni = vm.identifiertypes.filter(
                (item) => item.name.toUpperCase() == "DNI"
              );
              let arrayDocs = [{'tipo': Number(tipo), 'documento':documento}]
              arrayDocs.push({'tipo': Number(idTipoDni[0].id), 'documento':dni});
              //console.log("Consultando identificadores...");
              //arrayDocs.forEach(async (element) => {

                for (let i = 0; i < arrayDocs.length; i++) {
                    //console.log(arrayDocs[i]);
                    let query = "IdentifierTypeId=" + Number(arrayDocs[i].tipo);
                    query = query + "&Identifier=" + arrayDocs[i].documento;

                    const resp = await vm.$axiosApi
                      .getByQuery(vm.routePersons, query)
                      .then((r) => {
                        //console.log(r);
                        if (r.data.data) {

                          if( r.data.data.length>0){
                            return r.data.data;
                          }
                        }
                      })
                      .catch(function (error) {
                        console.log(error);
                        vm.snackbar = true;
                        vm.text = "Error al consultar personas. Error: " + error;
                        vm.color = "error";
                      });

                     if(resp){
                        i = arrayDocs.length;
                        return resp;
                      }

                    //return resp;
                }
                /*}

              );*/

              //console.log("End consultando identificadores...");
              //console.log(resp);
              return resp;
            };
            if (vm.editedItem.id) {
              if (vm.tDocAnt == tipo.id && vm.docAnt == documento) {
                return;
              } else if (vm.tDocAnt == "" && vm.docAnt == "") {
                return;
              }
            } else if (vm.tDocAnt == tipo.id && vm.docAnt == documento) {
              return;
            }

            let resp = await forLoopIdentifiers(tipo.id, documento);

            this.tDocAnt = Number(tipo.id);
            this.docAnt = documento;

            if (resp && resp.length > 0) {

              let tituloSwal =
                "El " +
                tipo.name +
                ": " +
                documento +
                " ya esta registrado. Desea utilizar la información?";
              //title: "<b>" + tituloSwal + " Datos del " + this.title + "?</b>",
              //text: "Presione Aceptar para confirmar los datos del " + this.title,
              Swal.fire({
                title: tituloSwal,
                icon: "question",
                html: "Presione Aceptar para completar el formulario con los datos existentes",
                showCloseButton: false,
                showCancelButton: true,
                focusConfirm: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Aceptar",
                cancelButtonColor: "#d33",
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.fire({
                    title: "Aguarde un instante!",
                    icon: "info",
                    html: "Cargando datos de la persona",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    willOpen: () => {
                      Swal.showLoading();
                    },
                  });
                  let id = resp[0].id;
                  vm.$axiosApi
                    .getById(vm.route, id)
                    .then((r) => {
                      //console.log(r.data.data);
                      if (r.data.data) {
                        Swal.close();
                        vm.editedItem.id = Number(r.data.data.id);
                        vm.editedItem.businessName = r.data.data.businessName;
                        vm.editedItem.lastName = r.data.data.lastName;
                        vm.editedItem.firstName = r.data.data.firstName;
                        vm.editedItem.birthDate = r.data.data.birthDate
                          ? vm.$formatDate(r.data.data.birthDate.substring(0, 10))
                          : "";
                          vm.editedItem.birthPlace = r.data.data.birthPlace;
                          vm.editedItem.genderId = r.data.data.gender;
                          vm.$set(vm.editedItem, 'maritalStatusId', r.data.data.maritalStatus);
                          vm.editedItem.pais_dni = {
                          id: r.data.data.nationality,
                        };
                        vm.editedItem.nacionalidad = {
                          id: r.data.data.nationality,
                        };
                        vm.editedItem.activity = r.data.data.activity;
                        vm.editedItem.mail = r.data.data.emails[0].email;
                        vm.editedItem.telefono =
                          r.data.data.phones[0].phoneNumber;
                          vm.editedItem.personType = {
                          id: Number(r.data.data.personType),
                        };
                        //falta cargar los domicilios
                        let domicilios = r.data.data.addresses;
                        for (let i = 0; i < domicilios.length; i++) {
                          //console.log(Number(domicilios[i].addressTypeId));
                          if (Number(domicilios[i].addressTypeId) == 1) {
                            //this.editedItem.domicilioReal = domicilios[i].address;
                            vm.editedItem.domicilioReal.calle =
                              domicilios[i].address.street;
                              vm.editedItem.domicilioReal.numero =
                              domicilios[i].address.number;
                              vm.editedItem.domicilioReal.cp =
                              domicilios[i].address.postCode;
                              vm.editedItem.domicilioReal.localidad =
                              domicilios[i].address.location;
                              vm.editedItem.domicilioReal.partido =
                              domicilios[i].address.partido;
                              vm.editedItem.domicilioReal.provincia = {
                              name: domicilios[i].address.province,
                            };
                            vm.editedItem.domicilioReal.barrio = {
                              name: domicilios[i].address.neighborhood,
                            };
                            vm.keyDomicilioReal = vm.keyDomicilioReal + 1;

                          } else {
                            vm.editedItem.domicilioLegal.calle =
                              domicilios[i].address.street;
                              vm.editedItem.domicilioLegal.numero =
                              domicilios[i].address.number;
                              vm.editedItem.domicilioLegal.cp =
                              domicilios[i].address.postCode;
                              vm.editedItem.domicilioLegal.localidad =
                              domicilios[i].address.location;
                              vm.editedItem.domicilioLegal.partido =
                              domicilios[i].address.partido;
                              vm.editedItem.domicilioLegal.provincia = {
                              name: domicilios[i].address.province,
                            };
                            vm.editedItem.domicilioLegal.barrio = {
                              name: domicilios[i].address.neighborhood,
                            };
                            vm.keyDomicilioLegal = this.keyDomicilioLegal + 1;
                          }
                        }
                      }
                    })
                    .catch(function (error) {
                      console.log(error);
                      //this.snackbar = true;
                      //this.text = "Error al consultar personas. Error: " + error;
                      //this.color = "error";
                    });
                }
                else{
                  //no desea utilizar los datos, pero igual debe actualizarse
                  if(typeof resp[0] !== "undefined"){
                    vm.editedItem.id = Number(resp[0].id);
                  }
                }
              });
              //return resp;
            } else {
              vm.editedItem.id = null;
              return false;
            }
          } else {
            return false;
          }
        },


        Vue.prototype.$filteredData=  function (item)
        {
          let vm = this;
          let conditions = [];
          for (var k in vm.filters) {

					   if (vm.filters[k].length >0) {
							vm.filterKey =k;

              let a ="vm.filterBy"+k.charAt(0).toUpperCase() + k.slice(1);
console.log(a);
              conditions.push( eval(a));
          	}
					}

          if (conditions.length > 0) {
            let datos = [];
            if(typeof vm.desserts.data != 'undefined')
            {
              datos = vm.desserts.data;
            }
            else
            {
              datos = vm.desserts;
            }
            return datos.filter((dessert) => {
              return conditions.every((condition) => {                
                return condition(dessert);
              });
            });
          }

          return vm.desserts;
        }


    //////////////////////////////////////////////////////////////////////
    Vue.prototype.$filteredData2=  function (item)
        {
          let vm = this;
          let conditions = [];




          for (var k in vm.filters) {
					   if (vm.filters[k].length >0) {
							vm.filterKey =k;


              //conditions.push( vm.filterData);
              vm.filterData(k);



              //conditions.push( eval(a));
          	}
					}

/*

          if (conditions.length > 0) {
            return vm.desserts.filter((dessert) => {
              return conditions.every((condition) => {
                return condition(dessert);
              });
            });
          }*/

          return vm.desserts;
        }


        Vue.prototype.$filterBy= function (item,filterKey)
        {
            let k = filterKey;

            if (k == "activo") {
              if (this.filters[k].toLowerCase() == "si" || this.filters[k].toLowerCase() == "s")
                return item[k]==1;
              if (this.filters[k].toLowerCase() == "no" || this.filters[k].toLowerCase() == "n")
                return item[k]==0;
            }

            return String(item[k])
              .toLowerCase()
              .includes(this.filters[k].toLowerCase());

      }



     Vue.prototype.$getListForSelect=  function ($route) {
      return new Promise((resolve, reject) => {
        let vm = this;
        //Swal.alertGetInfo("Obteniendo información <br><b></b>");

           vm.$axiosApi.get($route).then((response) => {

            let options = response.data.data;



              if(typeof options[0] !== 'undefined'){
                    if($route === 'usuarios')
                    {
                          this[$route] = options.filter(o => o.enabled == true)
                    }
                    else if ($route == 'roles' || $route =="provincias")
                    {
                          this[$route] = options
                    }
                    else if($route == "identifiertypes"){
                        this[$route] = options.filter(o => o.isActive == true)
                    }
                    else

                    {
                      this[$route] = options.filter(o => o.activo == "1")

                    }
                    //this.$toastr.removeByName("toast-"+$route);
                    resolve(response.data);
              }
              reject(new Error('Error interno del servidor'));

          })
          .catch((error) => {
            reject(error);
          });

        });
      }



        Vue.prototype.$getData = async function(arrayUrl) {

          let style="width: 51px;    height: 19px;  font-size: 10px; border-radius: 30px;  padding-top: 3px;";
          let estadoPendiente =
            '<div class="swal2-loader" style="display: flex;zoom: 60%;"></div>';
          let estadoError =
            "<i class='mdi mdi-close color-red' style='font-size: 1.7rem;'></i>";
          let estadoOk =
            "<i class='mdi mdi-check color-green' style='font-size: 1.7rem;'></i>";


          var stringAlert = "<div id='contenido'><table class='table' style='width:200px;margin-left:27%'><tbody>";

          arrayUrl.map(function(cadena) {

            let nombre =cadena.replace(/_/g, " ")
            stringAlert = stringAlert +

            "<tr style='height:30px'>" +

            "<td style='width:25%;text-align: center;''>" +
            "<div id='div_"+cadena+"'>" +
            estadoPendiente+"</div>" +
            "</td>" +
            "<td style='width:80%;text-align: left;'><b>"+cadena.replace(/_/g, " ")+"</b></td>" +
            "</tr>";

          });
          stringAlert = stringAlert +"</tbody></table></div>";

          //Swal.alertGetInfo();



          const swalGetData = Swal.mixin({
            title: "Aguarde un instante!",
            icon: "info",
            html: "Obteniendo información: <br>"+stringAlert+"",
            allowOutsideClick: false,
            showConfirmButton: false,
            showCancelButton:false,
            //showClass: {  popup: 'animate__animated animate__fadeInDown' },
            hideClass: {  popup: 'animate__animated animate__fadeOutUp' },

            willOpen: () => {
              Swal.showLoading();
            },
          });
          swalGetData.fire();

          let hayError =false;
          try {
            const responses = [];

            for (let i = 0; i < arrayUrl.length; i++) {
              var div = document.getElementById("div_"+arrayUrl[i]);

              try {
                 let  response = '';

                switch(arrayUrl[i])
                {

                  case "paises":


                    response = await this.$getListPaisesForSelect(arrayUrl[i], i);

                    responses.push(response);
                    if (response.status === 200) {
                      //console.log(`La solicitud en el índice ${i} fue exitosa.`);
                      div.innerHTML = estadoOk;
                      // Aquí puedes tomar decisiones basadas en una solicitud exitosa si es necesario.
                    } else {
                      console.log(`La solicitud en el índice ${i} falló con el código ${response.status}.`);
                    }
                  break;

                  case  "genders":
                  case  "maritalstatuses":



                      response = await this.$getExternalListForSelect(arrayUrl[i], i);

                      responses.push(response);

                      if (response.succeeded ) {
                        //console.log(`La solicitud en el índice ${i} fue exitosa.`);
                        div.innerHTML = estadoOk;
                        // Aquí puedes tomar decisiones basadas en una solicitud exitosa si es necesario.
                      } else {

                        console.log(`La solicitud ${arrayUrl[i]} en el índice ${i} falló con el código ${response.errors}.`);
                      }
                  break;
                  case "identifiertypes":
                    response = await this.$getListForSelect(arrayUrl[i], i);

                      responses.push(response);

                      if (response.code === 200) {
                        //console.log(`La solicitud en el índice ${i} fue exitosa.`);
                        div.innerHTML = estadoOk;
                        // Aquí puedes tomar decisiones basadas en una solicitud exitosa si es necesario.
                      } else {
                        console.log(`La solicitud en el índice ${i} falló con el código ${response.status}.`);
                      }



                  break;

                  default:
                      response = await this.$getListForSelect(arrayUrl[i], i);

                      responses.push(response);

                      if (response.code === 200) {
                        //console.log(`La solicitud en el índice ${i} fue exitosa.`);
                        div.innerHTML = estadoOk;
                        // Aquí puedes tomar decisiones basadas en una solicitud exitosa si es necesario.
                      } else {
                        console.log(`La solicitud en el índice ${i} falló con el código ${response.status}.`);
                      }

                }


              } catch (error) {
                //console.error(`Error en la solicitud en el índice ${i}:`, error);
                hayError =true;
                div.innerHTML = estadoError;

                const contenido = document.querySelector('#contenido');
                    contenido.innerHTML +="<br>La solicitud a: <b>"+arrayUrl[i].replace(/_/g, " ")+"</b> ha fallado. Por favor, inténtelo de nuevo o comuníquese con el administrador.";



              }

            }

            if(responses.length == arrayUrl.length)
            {
              setTimeout(() => {
              //console.log(`Todas las ${responses.length} peticiones GET se han completado con éxito.`);

              swalGetData.close();},500);
            }



          } catch (error) {
            console.error('Error general:', error);
          }

          if(hayError)
              {
                contenido.innerHTML += '<br><button id="confirmButton" class="swal2-deny swal2-styled">Cancelar</button>';

                const confirmButton = document.querySelector('#confirmButton');
                confirmButton.addEventListener('click', () => {

                  swalGetData.close();
                })
              }



        }





      Vue.prototype.$exit = async function  (id) {
        let vm = this;

        Swal.fire({
          title: "<b>Desea salir del trámite?</b>",
              icon: "question",
              html:
                "Para <b>continuar con la carga en otro momento</b> presione <b>Guardar y Salir</b>. <p>" +
                "Para <b>salir sin guardar</b> los datos presione <b>Salir</b>. <p>" +
                "Presione <b>Cancelar</b> para seguir cargando el trámite.<p>",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Guardar y Salir",
          denyButtonText: "Salir",
          cancelButtonText: "Cancelar",
          confirmButtonColor: "green",
          denyButtonColor: "blue",
          cancelButtonColor: "red",
          hideClass: {  popup: 'animate__animated animate__fadeOutUp' },
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {

            if(!id){
              Swal.fire({
                icon: "error",
                title: "Registro de Trámite",
                confirmButtonText: "Aceptar",
                html:"Para guardar los datos temporalmente, debe avanzar de sección",
                hideClass: {  popup: 'animate__animated animate__fadeOutUp' },

              })
              return;
            }

            Swal.alertGetInfo("Guardando datos del trámite");
            vm.$saveInProgress();
            /*this.$router.push({
              name: "f01_autos"
            });*/
          } else if (result.isDenied) {
            Swal.alertGetInfo("Saliendo del trámite");
            vm.$deleteTramite(id);
          }
        });
      },

      Vue.prototype.$saveInProgress = async function () {
        await this.$sleep(1000);
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Registro de Trámite",
              confirmButtonText: "Aceptar",
              html:"El trámite ha quedado en progreso, disponible para ser editado",
              hideClass: {  popup: 'animate__animated animate__fadeOutUp' },

            }).then((r) => {
              if (r.isConfirmed) {
                this.$router.push({
                  name: "f01_autos"
                });
              }
            });
      },

      Vue.prototype.$deleteTramite = async function (id) {
        let vm = this;
        // si es una edicion: no elimino el registro solo elimino los datos de la carga en pantalla
        // si es un alta: primera vez cargando en cualquier etapa
        // elimino el la carga, seteamos el deleted_at

        if (typeof this.$route.params.id != 'undefined') {
          await this.$sleep(1000);
            Swal.close();
          Swal.fire({
            icon: "warning",
            title: "Registro de Trámite",
            confirmButtonText: "Aceptar",
            html:"Se han descartado todos los datos ingresados"
          }).then((r) => {
            if (r.isConfirmed) {
              this.$router.push({
                name: "f01_autos"
              });
            }
          });
        }
        else{
          await vm.$axiosApi.delete('formularios', id)
          .then((res) => {
              Swal.close();
              Swal.fire({
                icon: "warning",
                title: "Registro de Trámite",
                confirmButtonText: "Aceptar",
                html:"Se han descartado todos los datos ingresados"
              }).then((r) => {
                if (r.isConfirmed) {
                  this.$router.push({
                    name: "f01_autos"
                  });
                }
              });
            })
            .catch((e) => {
              Swal.close();
                vm.$respuesta(vm, e, 0, true);
            });
        }

                //Swal.close();
      },

      Vue.prototype.$getExternalListForSelect = async function ($route) {
        return new Promise((resolve, reject) => {

        let vm = this;
        //Swal.alertGetInfo("Obteniendo información <br><b></b>");

          vm.$axiosApi.getExternal($route).then((response) => {

            let options= response.data.data;
              if(typeof options[0] !== 'undefined'){
                    this[$route] = options.filter(o => o.isActive == true)

              }
              /*
              if($route == "identifiertypes")
              {
                this[$route] = options;
                console.log($route)            ;
                console.log(options)            ;

                let cuitCuils = this[$route] .filter(
                  (item) =>
                    item.name.toUpperCase() == "CUIT" || item.name.toUpperCase() == "CUIL"
                );
                let documentos = this[$route] .filter(
                  (item) =>
                    (item.name.toUpperCase() != "CUIT" && item.name.toUpperCase() != "CUIL" && item.name.toUpperCase() != "PERSONERIA JURIDICA")
                );
                let cuits = this[$route] .filter(
                  (item) =>
                    (item.name.toUpperCase() == "CUIT" )
                );
                this.tiposCuit = cuits;
                this.tiposCuitCuil = cuitCuils;
                this.tiposDocumentos = documentos;

              }*/
              if($route =="provincias")
              {
                this[$route] = options;
              }
            resolve(response.data);
              //Swal.close();
          })
          .catch((error) => {
            reject(error);

          });
        });
      }

        Vue.prototype.$getListPaisesForSelect=   function ($route) {

          return new Promise((resolve, reject) => {

            let vm = this;

              vm.$axiosApi.get($route).then((response) => {

                let options = [];
                if(response.status == 200 && response.data.code ==200)
                {
                  options= response.data.data;

                }
                else
                {

                      /*fetch('/restcountries/all.json')
                      .then(response => {
                        return response.json();
                      })
                      .then(data => {
                        options = data;
                      })
                      .catch(error => {
                        console.error('Error al cargar los datos JSON:', error);
                      });*/
                }
                let el ={};
                let element ={};


                for (let index = 0; index < options.length; index++) {
                  element = options[index];
                  //el.id = index+1;
                  //el.id = element.ccn3;
                  el.id = element.translations.spa.common.toUpperCase();
                  el.code = element.cca3;
                  el.name = element.translations.spa.common.toUpperCase();

                  this[$route].push(el);
                  el ={};

                }
                this[$route].sort((a, b) => a.name.localeCompare(b.name));
                  //Swal.close();
                  resolve(response);


              })
              .catch((error) => {

                reject(error);
              /* this.snackbar = true;
                this.text = "Error al obtener "+$route.replace("_"," ")+". Error: " + error.message;
                this.color = "error";*/
              });
            });
        }



          Vue.prototype.$pdfExport= async function (id) {
            let vm = this;
            vm.$axiosApi.pdfExport(vm.route+"_pdf",id);

          }



          Vue.prototype.$hideMenu = function(){

            if ( this.$root.$children[0].$children[0].$children[0].$children[0].$vnode.componentOptions.tag == "dashboard-core-app-bar")
                  this.$root.$children[0].$children[0].$children[0].$children[0].setDrawer( false );
            if (this.$root.$children[0].$children[0].$children[0].$children[1].$vnode.componentOptions.tag == "dashboard-core-app-bar")
                  this.$root.$children[0].$children[0].$children[0].$children[1].setDrawer(false );


          }

          Vue.prototype.$goBack = async function  () {

            let msj = "Desea volver a la pagina anterior ?";
            let vm = this;
            Swal.fire(
              Swal.objQuestion(
                msj,
                ""
              )
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.go(-1);
              }
            });

           }

           Vue.prototype.$alertDev = function(){

            Swal.fire({
              icon: "info",
              title: "Ups!",
              html: "Desarrollo de Software<br>Esta funcionalidad esta en progreso",
            });

          }

          Vue.prototype.$getNotificaciones = function(){

            let vm = this;
            //Swal.alertGetInfo("Buscando información");
            let userId = vm.$store.state.user.id

            vm.$axiosApi.getByCriteria('notificacionesByUser', userId)
          .then((r) => {
            if(r.data.data){
              vm.$store.state.user.notificaciones = r.data.data;
            }
            else{
              vm.$store.state.user.notificaciones = []
            }
          })
          .catch(function (error) {
            console.log(error);
          });

          }

    }


}
